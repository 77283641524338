import Price from '../price/price';
import styles from './styles.module.scss';

const PriceList = () => {
	const modelBenefits = [
		'Premier display on the frontpage.',
		'Premier display on member profile.',
		'Private chat with members.',
		'Access to broadcast.',
	];

	const memberBenefits = [
		'Access to all models on the platform.',
		'Access to all broadcast events from all models.',
		'Private chat with a model.',
		'Comments on model profile.',
	];

	const clubBenefits = [
		'Premier  display on the frontpage.',
		'Access to promote events.',
		'Private chat with members on the platform.',
	];

	return (
		<section>
			<h3 className={styles.prices}>Subscription Plans</h3>
			<section className={styles.section}>
				<Price amount='$19.99' subscriptionType='Member' frequency='monthly' benefits={memberBenefits} />
				<Price amount='$29.99' subscriptionType='Model' frequency='monthly' benefits={modelBenefits} />
				<Price amount='$49.99' subscriptionType='Club' frequency='monthly' benefits={clubBenefits} />
			</section>
		</section>
	);
};

export default PriceList;
