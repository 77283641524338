import { useState } from 'react';
import axios from 'axios';
import errorFormmatter from '../../../utils/errorFormatter';
import { useMutation } from 'react-query';
import notification from '../../../utils/notification';

const r = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const FormSubscribe = () => {
   const [email, setEmail] = useState('');

   const subscribeToNewsletterCall = (payload) => axios.post('/SetUp/NewsLetter', payload);

   const subscribeToNewsletterMutation = useMutation(
      'subscribe-newletter',
      subscribeToNewsletterCall,
      { retry: 0 }
   );

   const handleSubmit = async (e) => {
      e.preventDefault();

      try {
         if (!email) {
            notification({
               title: 'Subscribe to Newsletter',
               type: 'danger',
               message: 'Email required',
            });
            return;
         }

         if (!r.test(email)) {
            notification({
               title: 'Subscribe to Newsletter',
               type: 'danger',
               message: 'Invalid email address',
            });
            return;
         }

         const payload = { email };

         await subscribeToNewsletterMutation.mutateAsync(payload, {
            onSuccess: () => {
               // reset the form
               setEmail('');

               notification({
                  title: 'Subscribe to Newsletter',
                  type: 'success',
                  message: 'You have successfully subscribe to our newsletter',
               });
            },
         });
      } catch (error) {
         notification({
            title: 'Subscribe to Newletter Error',
            type: 'danger',
            message: errorFormmatter(error),
         });
      }
   };

   const handleChange = (e) => {
      setEmail(e.target.value);
   };

   return (
      <form onSubmit={handleSubmit} className='subscribe-form'>
         <div className='subscribe-form--input'>
            <input
               className='footer-subscribe--input subscribeJS text-black'
               type='text'
               id='site-subscribe'
               name='email'
               onChange={handleChange}
               value={email}
               placeholder='Enter your e-mail'
               required
            />
         </div>
         <button onClick={handleSubmit} disabled={subscribeToNewsletterMutation.isLoading}>
            {subscribeToNewsletterMutation.isLoading ? 'Subscribing...' : 'Subscribe'}
         </button>
      </form>
   );
};

export default FormSubscribe;
