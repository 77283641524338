import Photo from '../../../custom/photo/Photo';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useActionCreator } from '../../../hooks/useActionCreators';

const ModelCard = ({ model, updateModel, modelType }) => {
	const history = useHistory();
	const { openModal } = useActionCreator();
	const { clientId, token } = useSelector(state => state?.user);
	const modelId = model?.registerId;

	const handleLike = async () => {
		if (typeof updateModel !== 'function') throw new Error('onLike is not a function');

		// open login modal when a user clicks like button without being logged in
		if (!token) {
			openModal();
			return;
		}

		try {
			const response = await axios.get(`LikeEndPoint/${modelId}/${clientId}/Likes`);
			const likeCount = response?.data?.modelLike?.likeCount;

			updateModel(prevModels => {
				return prevModels?.map(model => {
					if (model?.registerId === modelId) {
						model.like = likeCount;
						model.changeColor = !model.changeColor;
					}
					return model;
				});
			});
		} catch (error) {
			return false;
		}
	};

	return (
    <section className='models-big-item models-big-item__free'>
      <div
        className='models-big-item--img cursor-pointer'
        onClick={() =>
          history.push(
            `/detail/about?model=${model?.registerId}&lastName=${model?.lastName}&firstName=${model?.firstName}`
          )
        }
      >
        <Photo
          src={model?.profilePicUrl}
          alt={`${model?.firstName}${model?.lastName}`}
        />
        <div className='models-big-item--status'>
          <span className='models-big-item--status__quantity'>HD</span>
          <span className='models-big-item--status__category'>
            {modelType ? modelType : 'New Model'}
          </span>
        </div>
      </div>
      <div className='models-big-item-bottom'>
        <div className='models-big-item--name'>
          {model?.firstName} {model?.lastName}
        </div>
        <div
          className={classNames(
            'models-big-item--like',
            model?.changeColor && 'models-big-item--liked'
          )}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='19'
            viewBox='0 0 20 19'
            fill='none'
            onClick={handleLike}
          >
            <path
              d='M19.4101 5.62556C19.1312 2.55516 16.9582 0.327516 14.2387 0.327516C12.4269 0.327516 10.768 1.30259 9.83457 2.86536C8.90958 1.28238 7.31869 0.327148 5.53595 0.327148C2.81684 0.327148 0.643456 2.55479 0.364893 5.62519C0.342843 5.76081 0.252439 6.47456 0.527327 7.63855C0.92349 9.31746 1.83856 10.8446 3.17294 12.0538L9.83016 18.0957L16.6017 12.0541C17.9361 10.8446 18.8511 9.31783 19.2473 7.63855C19.5222 6.47493 19.4318 5.76118 19.4101 5.62556ZM18.5318 7.47022C18.1702 9.00358 17.3319 10.4006 16.11 11.5076L9.83457 17.107L3.66686 11.5091C2.44273 10.3999 1.60483 9.00322 1.24285 7.46985C0.982657 6.36835 1.0896 5.74611 1.08997 5.74206L1.09548 5.70494C1.33435 3.01494 3.20161 1.06222 5.53595 1.06222C7.25842 1.06222 8.77471 2.12073 9.49427 3.82426L9.83274 4.62659L10.1712 3.82426C10.8794 2.14682 12.4758 1.06259 14.239 1.06259C16.573 1.06259 18.4406 3.01531 18.6843 5.74023C18.685 5.74611 18.792 6.36871 18.5318 7.47022Z'
              fill='white'
              stroke='white'
              strokeWidth='0.4'
            />
          </svg>
          {model?.like ?? 0}
        </div>
      </div>
      <article className='models-big-item-bottom2'>
        {model?.online && (
          <div className='models-big-item-category'>Free chat</div>
        )}
        <div className='models-big-item--flag'>
          <img
            src={model?.country?.countryFlagURL}
            alt='country flag'
            loading='lazy'
          />
        </div>
      </article>
    </section>
  );
};

export default ModelCard;
