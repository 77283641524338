import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ModelCard from '../components/card/ModelCard';
import Scrollbar from 'react-scrollbars-custom';
import { useQuery } from 'react-query';
import { getMemberProfile } from '../../request/member';
import Spinner from '../../custom/spinner/Spinner';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../hooks/useActionCreators';
import moment from 'moment';
import classNames from 'classnames';
import { getPremiumModels } from '../../request/member';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import notification from '../../utils/notification';

const LkUser = () => {
	const clientId = useSelector(state => state?.user?.clientId);
	const token = useSelector(state => state?.user?.token);
	const [messages, setMessages] = useState([]);
	const [socket, setSocket] = useState(null);
	const [selectedChats, setSelectedChats] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const scrollRef = useRef(null);
	const [activeClasses, setActiveClasses] = useState(new Array(9).fill(false));
	const [favoriteModels, setFavoriteModels] = useState([]);
	const [browserHistory, setBrowserHistory] = useState([]);
	const { logOut } = useActionCreator();
	const [premiumModels, setPremiumModels] = useState([]);
	const chatBaseUrl = process.env?.REACT_APP_NODE_URL;

	useEffect(() => {
		const socket = io(chatBaseUrl, {
			path: '/socket/v1/chat',
			auth: { token },
		});

		socket.emit('join room', clientId);
		setSocket(socket);

		socket.emit('chat history', { pageNumber });

		socket.on('chat history', data => {
			setMessages(data?.data ?? []);
		});

		socket?.on('delete chats', () => {
			setMessages([]);
			socket.emit('chat history', { pageNumber });
		});

		socket.on('error', ({ message }) => {
			notification({
				title: 'Private show',
				type: 'danger',
				message,
			});
		});

		return () => {
			socket.off('chat history');
			socket.off('join room');
			socket.off('delete chats');
			socket.off('error');
			socket?.disconnect?.();
		};
	}, []);

	const formatDate = date => {
		const formattedTime = moment(date).format('h:mm A');
		return `${formattedTime} `;
	};

	const handleCurrentMessage = message => {
		const messageId = message?._id;

		setSelectedChats(prev => {
			if (prev.indexOf(messageId) !== -1) {
				return prev.filter(id => id !== messageId);
			} else {
				return [...prev, messageId];
			}
		});
	};

	const handleDeleteChat = () => {
		if (!socket) return;

		let itemsToDelete = [...selectedChats];

		if (selectedChats?.length > 0) itemsToDelete = [...selectedChats];

		if (itemsToDelete?.length < 1)
			return notification({ type: 'danger', message: 'select a chat(s) to delete', title: 'delete chat' });

		socket.emit('delete chats', itemsToDelete);
	};

	const handleSelectAll = () => {
		const selectedChats = messages.map(message => message?._id);

		for (let messageId of selectedChats) {
			setSelectedChats(prev => {
				if (prev.indexOf(messageId) !== -1) {
					return prev.filter(id => id !== messageId);
				} else {
					return [...prev, messageId];
				}
			});
		}
	};

	const handleMarkAsRead = () => {
		if (!socket) return;

		let itemsToMarkAsRead = [...selectedChats];

		if (itemsToMarkAsRead?.length < 1)
			return notification({ type: 'danger', message: 'select a chat(s ) to mark as read' });

		socket.emit('mark as read', itemsToMarkAsRead);
	};

	const { data, isErrror, error, isLoading } = useQuery(['member-profile'], () => getMemberProfile(clientId), {
		enabled: !!clientId,
		onSuccess: data => {
			setFavoriteModels(data?.favoriteModelData ?? []);
			setBrowserHistory(data?.memberHistoryData ?? []);
		},
	});

	const errorMessage = error?.response?.data?.message ?? error?.message;

	const handleGetPremiumModels = async () => {
		try {
			const response = await getPremiumModels();

			setPremiumModels(response?.models ?? []);
		} catch (error) {}
	};

	const handleLogOut = () => {
		logOut();
	};

	useEffect(() => {
		handleGetPremiumModels();
	}, []);

	if (isLoading) return <Spinner width={50} height={50} />;
	if (isErrror) return <p className='error'>{errorMessage}</p>;

	return (
		<main>
			<section className='lk'>
				<div className='container'>
					<div className='lk-row'>
						<div className='lk-column'>
							<div className='lk-row'>
								<div className='lk-row-akk'>
									<div className='lk-profile'>
										<div className='lk-profile--top'>
											<div className='lk-profile--photo'>
												<img
													src={data?.profilePicUrl ?? data?.avatarUrl}
													alt='user avatar'
													className='lk-profile--img'
												/>
											</div>
											<div>
												{data?.accountType?.toLowerCase() === 'basic' && (
													<div className='lk-profile--status premium'>Free account</div>
												)}
												<div className='lk-profile--name'>{data?.fullName}</div>
												<div className='lk-profile--link'>
													<Link to='/lk-edit'>Edit profile</Link>
													<button onClick={handleLogOut} className='lk-profile-btn'>
														Sign Out
													</button>
												</div>
											</div>
										</div>
										<div className='lk-profile--bottom'>
											<div className='lk-profile--info'>
												<strong>Joined</strong>
												<p>{moment(data?.dateJoined).format('DD.MM.YYYY')}</p>
											</div>
											<div className='lk-profile--info'>
												<strong>Gender</strong>
												<p>{data?.gender}</p>
											</div>
											<div className='lk-profile--info'>
												<strong>Age</strong>
												<p>{data?.age ? data?.age : 'Unspecified'}</p>
											</div>
											<div className='lk-profile--info'>
												<strong>Country</strong>
												<p>{data?.country?.countryName}</p>
											</div>
											<div className='lk-profile--info'>
												<strong>Favorite models</strong>
												<p>{data?.favoriteModel}</p>
											</div>
										</div>
									</div>

									<div className='lk-balance'>
										<div className='lk-balance--top'>
											<div className='lk-balance--bucks'>
												<svg
													width='51'
													height='51'
													viewBox='0 0 51 51'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<circle cx='25.5' cy='25.5' r='24.5' stroke='white' stroke-width='2' />
													<path
														d='M26.5015 12.75C26.5015 12.1977 26.0538 11.75 25.5015 11.75C24.9492 11.75 24.5015 12.1977 24.5015 12.75H26.5015ZM24.5015 16.3929C24.5015 16.9451 24.9492 17.3929 25.5015 17.3929C26.0538 17.3929 26.5015 16.9451 26.5015 16.3929H24.5015ZM31.8765 17.3929C32.4288 17.3929 32.8765 16.9451 32.8765 16.3929C32.8765 15.8406 32.4288 15.3929 31.8765 15.3929V17.3929ZM19.1265 33.6071C18.5742 33.6071 18.1265 34.0549 18.1265 34.6071C18.1265 35.1594 18.5742 35.6071 19.1265 35.6071V33.6071ZM24.5015 38.25C24.5015 38.8023 24.9492 39.25 25.5015 39.25C26.0538 39.25 26.5015 38.8023 26.5015 38.25H24.5015ZM24.5015 12.75V16.3929H26.5015V12.75H24.5015ZM31.8765 15.3929H22.7694V17.3929H31.8765V15.3929ZM22.7694 26.5H28.2337V24.5H22.7694V26.5ZM28.2337 33.6071H25.5015V35.6071H28.2337V33.6071ZM25.5015 33.6071H19.1265V35.6071H25.5015V33.6071ZM24.5015 34.6071V38.25H26.5015V34.6071H24.5015ZM31.7872 30.0536C31.7872 32.0162 30.1963 33.6071 28.2337 33.6071V35.6071C31.3008 35.6071 33.7872 33.1207 33.7872 30.0536H31.7872ZM28.2337 26.5C30.1963 26.5 31.7872 28.091 31.7872 30.0536H33.7872C33.7872 26.9864 31.3008 24.5 28.2337 24.5V26.5ZM17.2158 20.9464C17.2158 24.0136 19.7022 26.5 22.7694 26.5V24.5C20.8068 24.5 19.2158 22.909 19.2158 20.9464H17.2158ZM22.7694 15.3929C19.7022 15.3929 17.2158 17.8793 17.2158 20.9464H19.2158C19.2158 18.9838 20.8068 17.3929 22.7694 17.3929V15.3929Z'
														fill='white'
													/>
												</svg>
											</div>
											<div className='lk-balance--wrapper'>
												<div className='lk-balance--title'>Your balance</div>
												<div className='lk-balance--price'>{data?.creditBalance}</div>
											</div>
											{/* <div className='lk-balance--btn btn btn__purple'>Get Credits</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='lk-column'>
							<div className='lk-message lk-message_pageLk'>
								<div className='lk-message-top'>
									<div className='lk-message--title'>Messages and notifications</div>
									<div className='lk-message--links'>
										<div className='lk-message--links-a'>
											<svg
												width='30'
												height='29'
												viewBox='0 0 30 29'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
												onClick={handleMarkAsRead}
											>
												<path
													className='color-fill'
													d='M29.9771 11.5637C29.977 11.5186 29.967 11.4504 29.9494 11.4112C29.9033 11.2439 29.8008 11.0912 29.6474 10.9845L25.353 7.99931V4.79915C25.353 4.37494 25.0089 4.03087 24.5847 4.03087H19.6441L15.4404 1.10833C15.1777 0.925463 14.8289 0.924701 14.5654 1.10681L10.3345 4.03087H5.41559C4.99125 4.03087 4.64731 4.37494 4.64731 4.79915V7.96164L0.331992 10.9441C0.119707 11.0908 0.00486328 11.3254 0.00123047 11.5652C0.00112963 11.5672 0.000887802 11.569 0.000644963 11.571C0.000323366 11.5735 0 11.576 0 11.5787L0.0229102 28.2331C0.0232031 28.4368 0.104414 28.6321 0.248613 28.776C0.392637 28.9196 0.587754 29.0003 0.791191 29.0003H0.792246L29.2328 28.9612C29.657 28.9606 30.0006 28.6161 30 28.1918L29.9771 11.5637ZM25.3529 9.87074L27.8079 11.5769L25.3529 13.2836V9.87074ZM15.0009 2.67402L16.9526 4.03081H13.0379L15.0009 2.67402ZM6.18387 5.56744H23.8164V14.3519L17.8963 18.4676L15.4826 16.5953C15.4804 16.5936 15.4784 16.5923 15.4764 16.5909C15.4749 16.59 15.4735 16.589 15.472 16.5879C15.2022 16.3855 14.8206 16.3781 14.5407 16.5953L12.1095 18.4811L6.18387 14.3859V5.56744ZM4.64731 9.82943V13.324L2.11922 11.5768L4.64731 9.82943ZM1.53855 13.0441L10.8356 19.4692L1.5573 26.6659L1.53855 13.0441ZM3.03955 27.4606L15.0117 18.1745L26.9408 27.4278L3.03955 27.4606ZM19.1686 19.4545L28.4426 13.0071L28.4613 26.6629L19.1686 19.4545Z'
													fill='#D4D4D4'
												/>
												<path
													className='color-stroke'
													d='M29.9771 11.5637C29.977 11.5186 29.967 11.4504 29.9494 11.4112C29.9033 11.2439 29.8008 11.0912 29.6474 10.9845L25.353 7.99931V4.79915C25.353 4.37494 25.0089 4.03087 24.5847 4.03087H19.6441L15.4404 1.10833C15.1777 0.925463 14.8289 0.924701 14.5654 1.10681L10.3345 4.03087H5.41559C4.99125 4.03087 4.64731 4.37494 4.64731 4.79915V7.96164L0.331992 10.9441C0.119707 11.0908 0.00486328 11.3254 0.00123047 11.5652C0.00112963 11.5672 0.000887802 11.569 0.000644963 11.571C0.000323366 11.5735 0 11.576 0 11.5787L0.0229102 28.2331C0.0232031 28.4368 0.104414 28.6321 0.248613 28.776C0.392637 28.9196 0.587754 29.0003 0.791191 29.0003H0.792246L29.2328 28.9612C29.657 28.9606 30.0006 28.6161 30 28.1918L29.9771 11.5637ZM25.3529 9.87074L27.8079 11.5769L25.3529 13.2836V9.87074ZM15.0009 2.67402L16.9526 4.03081H13.0379L15.0009 2.67402ZM6.18387 5.56744H23.8164V14.3519L17.8963 18.4676L15.4826 16.5953C15.4804 16.5936 15.4784 16.5923 15.4764 16.5909C15.4749 16.59 15.4735 16.589 15.472 16.5879C15.2022 16.3855 14.8206 16.3781 14.5407 16.5953L12.1095 18.4811L6.18387 14.3859V5.56744ZM4.64731 9.82943V13.324L2.11922 11.5768L4.64731 9.82943ZM1.53855 13.0441L10.8356 19.4692L1.5573 26.6659L1.53855 13.0441ZM3.03955 27.4606L15.0117 18.1745L26.9408 27.4278L3.03955 27.4606ZM19.1686 19.4545L28.4426 13.0071L28.4613 26.6629L19.1686 19.4545Z'
													stroke='#D4D4D4'
												/>
												<path
													className='color-fill'
													d='M20.1991 8.64062H9.7966C9.37238 8.64062 9.02832 8.98469 9.02832 9.40891C9.02832 9.83312 9.37238 10.1772 9.7966 10.1772H20.1991C20.6234 10.1772 20.9674 9.83312 20.9674 9.40891C20.9674 8.98469 20.6234 8.64062 20.1991 8.64062Z'
													fill='#D4D4D4'
												/>
												<path
													className='color-stroke'
													d='M20.1991 8.64062H9.7966C9.37238 8.64062 9.02832 8.98469 9.02832 9.40891C9.02832 9.83312 9.37238 10.1772 9.7966 10.1772H20.1991C20.6234 10.1772 20.9674 9.83312 20.9674 9.40891C20.9674 8.98469 20.6234 8.64062 20.1991 8.64062Z'
													stroke='#D4D4D4'
												/>
												<path
													className='color-fill'
													d='M20.1991 12.6357H9.7966C9.37238 12.6357 9.02832 12.9798 9.02832 13.404C9.02832 13.8282 9.37238 14.1723 9.7966 14.1723H20.1991C20.6234 14.1723 20.9674 13.8282 20.9674 13.404C20.9674 12.9798 20.6234 12.6357 20.1991 12.6357Z'
													fill='#D4D4D4'
												/>
												<path
													className='color-stroke'
													d='M20.1991 12.6357H9.7966C9.37238 12.6357 9.02832 12.9798 9.02832 13.404C9.02832 13.8282 9.37238 14.1723 9.7966 14.1723H20.1991C20.6234 14.1723 20.9674 13.8282 20.9674 13.404C20.9674 12.9798 20.6234 12.6357 20.1991 12.6357Z'
													stroke='#D4D4D4'
												/>
											</svg>
											<div className='lk-message--tooltip'>
												<svg
													width='8'
													height='19'
													viewBox='0 0 8 19'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M4 0C4 10 6.66667 12.8333 8 13L6.5 17C4.33333 18.5 -2.62268e-07 20 0 14C2.62268e-07 8 2.66667 2.16667 4 0Z'
														fill='#080609'
													/>
												</svg>
												<div className='lk-message--tooltip-content'>Mark as read</div>
											</div>
										</div>
										{/* <div className={"lk-message--links-a selectedAll" +  (activeClasses[8]? " active" : " ")} onClick={() => this.addActiveClass(8)}> */}
										<div
											className={classNames('lk-message--links-a', activeClasses[0] ? ' selectedAll' : '')}
											onClick={handleSelectAll}
										>
											<svg
												width='26'
												height='26'
												viewBox='0 0 26 26'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													className='color-stroke'
													d='M16.4286 1H2C1.44772 1 1 1.44772 1 2V24C1 24.5523 1.44772 25 2 25H24C24.5523 25 25 24.5523 25 24V13'
													stroke='#D4D4D4'
													stroke-width='2'
													stroke-linecap='round'
												/>
												<path
													className='color-stroke'
													d='M7 12.1434L11.215 16.3584C11.2541 16.3975 11.3174 16.3975 11.3564 16.3584L25 2.71484'
													stroke='#D4D4D4'
													stroke-width='2'
													stroke-linecap='round'
												/>
											</svg>
										</div>
										<div className='lk-message--links-a' onClick={handleDeleteChat}>
											<svg
												width='26'
												height='29'
												viewBox='0 0 26 29'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													className='color-fill'
													fill-rule='evenodd'
													clip-rule='evenodd'
													d='M8.5324 4.4839C8.5324 3.59371 9.25404 2.87207 10.1442 2.87207H15.3651C16.2552 2.87207 16.9769 3.59371 16.9769 4.4839V5.578H8.5324V4.4839ZM18.9769 4.4839V5.578H24.5065C25.0588 5.578 25.5065 6.02571 25.5065 6.578C25.5065 7.13028 25.0588 7.578 24.5065 7.578H22.9212V25.4651C22.9212 27.122 21.578 28.4651 19.9212 28.4651H5.63838C3.98153 28.4651 2.63838 27.122 2.63838 25.4651V7.578H1C0.447715 7.578 0 7.13028 0 6.578C0 6.02571 0.447715 5.578 1 5.578H6.5324V4.4839C6.5324 2.48914 8.14947 0.87207 10.1442 0.87207H15.3651C17.3598 0.87207 18.9769 2.48914 18.9769 4.4839ZM4.63838 7.578V25.4651C4.63838 26.0174 5.08609 26.4651 5.63838 26.4651H19.9212C20.4735 26.4651 20.9212 26.0174 20.9212 25.4651V7.578H7.5324H4.63838ZM12.7658 9.52752C13.3181 9.52752 13.7658 9.97523 13.7658 10.5275V23.5285C13.7658 24.0808 13.3181 24.5285 12.7658 24.5285C12.2135 24.5285 11.7658 24.0808 11.7658 23.5285V10.5275C11.7658 9.97523 12.2135 9.52752 12.7658 9.52752ZM8.03511 10.7918C8.58739 10.7918 9.03511 11.2395 9.03511 11.7918V22.2646C9.03511 22.8169 8.58739 23.2646 8.03511 23.2646C7.48282 23.2646 7.03511 22.8169 7.03511 22.2646V11.7918C7.03511 11.2395 7.48282 10.7918 8.03511 10.7918ZM18.2332 11.7986C18.2332 11.2463 17.7855 10.7986 17.2332 10.7986C16.6809 10.7986 16.2332 11.2463 16.2332 11.7986V22.2714C16.2332 22.8237 16.6809 23.2714 17.2332 23.2714C17.7855 23.2714 18.2332 22.8237 18.2332 22.2714V11.7986Z'
													fill='#D4D4D4'
												/>
											</svg>
										</div>
									</div>
								</div>

								<div className='lk-message-wrapper'>
									<Scrollbar style={{ height: 386 }}>
										{messages?.map(message => (
											<div
												className={classNames(
													selectedChats.indexOf(message?._id) !== -1
														? 'active lk-message-item'
														: 'lk-message-item'
												)}
												key={message._id}
												onClick={() => handleCurrentMessage(message)}
											>
												<div className='lk-message-content'>
													<div className='lk-message-item--photo'>
														<img src={require('../../images/notImg.png')} alt='' />
													</div>
													<div>
														<div className='lk-message-item--name'>
															{message?.sender?.id !== clientId
																? message?.sender?.name
																: message?.receiver?.name}
														</div>
														<div className='lk-message-item--text'>{message?.message?.text}</div>
														<div className='lk-message-item--time'>{formatDate(message?.createdAt)}</div>
													</div>
												</div>
											</div>
										))}
									</Scrollbar>
								</div>

								{messages?.length > 0 && (
									<Link to='/lk-message' className='lk-message--more'>
										Show all messages
									</Link>
								)}
							</div>
						</div>
						<div className='lk-column !w-[300px]'>
							{data?.accountType?.toLowerCase() === 'basic' && (
								<div className='lk-info'>
									<div className='lk-info--title'>
										You have a free <br />
										account with <br />
										restrictions
									</div>
									<div className='lk-info--description'>
										Take a premium <br />
										subscription and <br />
										get full access <br />
										to opportunities
									</div>
									<Link to='/' className='btn btn__yellow lk-info--btn'>
										Get Premium
									</Link>
								</div>
							)}

							{/* <div className='flex-1 w-[max-content]'> */}
							<Swiper
								className='!w-[500px]'
								loop={true}
								modules={[Autoplay]}
								autoplay={{
									delay: 5000,
								}}
								spaceBetween={0}
								slidesPerView={1}
							>
								{premiumModels?.map((model, index) => {
									return (
										<SwiperSlide key={index} className='!w-[100%]'>
											<div className='lk-new'>
												<div className='lk-new--content'>
													<div className='lk-new--title'>New models</div>
													<div className='lk-new--name'>
														{model?.firstName} {model?.lastName}
													</div>
													<Link
														to={`/detail/about?model=${model?.registerId}&lastName=${model?.lastName}&firstName=${model?.firstName}`}
														className='btn btn__red lk-new--btn'
													>
														Take a look
													</Link>
												</div>
												<img src={model?.profilePicUrl} alt='' />
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>
							{/* </div> */}
						</div>
					</div>
					<div className='lk-row lk-row--advertising'>
						<img src={require('../.././images/trashimg/mainbanner.jpg')} alt='' />
					</div>
				</div>
			</section>
			<section className='lk-favourite'>
				<div className='container'>
					<div className='lk-favourite--top'>
						<div className='lk-favourite--title'>Favorite models</div>
						<Link to='/lk-favourite' className='lk-favourite--more'>
							Show all favorite models
						</Link>
					</div>
					<div className='lk-favourite--wrapper'>
						{favoriteModels?.length > 0 ? (
							favoriteModels?.map(favoriteModel => (
								<ModelCard
									model={favoriteModel}
									key={favoriteModel?.registerId}
									updateModel={setFavoriteModels}
									modelType={' '}
								/>
							))
						) : (
							<p className='no-available-data'>no favorite model</p>
						)}
					</div>
				</div>
			</section>
			<section className='lk-favourite'>
				<div className='container'>
					<div className='lk-favourite--top'>
						<div className='lk-favourite--title'>Browsing history</div>
						<Link to='/lk-history' className='lk-favourite--more'>
							Show all history
						</Link>
					</div>
					<div className='lk-favourite--wrapper'>
						{browserHistory?.length > 0 ? (
							browserHistory?.map(modelHistory => (
								<ModelCard
									model={modelHistory}
									key={modelHistory?.registerId}
									updateModel={setBrowserHistory}
									modelType={' '}
								/>
							))
						) : (
							<p>no available model history</p>
						)}
					</div>
				</div>
			</section>
		</main>
	);
};

export default LkUser;
