import styles from './styles.module.scss';

const Price = ({ amount, subscriptionType, frequency, benefits }) => {
	return (
		<article className={styles.article}>
			<div className={styles.wrapper}>
				<h4 className={styles.heading}>{amount}</h4>
				<p className={styles.para}>{frequency}</p>
			</div>
			<p className={styles.subscriptionType}>{subscriptionType}</p>

			<ul>
				{benefits?.map((benefit, index) => (
					<li key={index} className={styles.listItem}>
						{benefit}
					</li>
				))}
			</ul>
		</article>
	);
};

export default Price;
