import React, { useEffect, useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import Date from '../components/date/Date';
import axios from 'axios';
import { nanoid } from 'nanoid';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import validateInput from '../../utils/validationSchema';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

const RegistrationModelInfo = () => {
  const [firstName, setFirstName] = useState('First name');
  const [lastName, setLastName] = useState('Last name');
  const [build, setBuild] = useState('Build');
  const [country, setCountry] = useState('Country');
  const [countryId, setCountryId] = useState('');
  const [nationality, setNationality] = useState('Nationality');
  const [city, setCity] = useState('City');
  const [nickName, setNickName] = useState('Nick name');
  const [gender, setGender] = useState('Gender');
  const [height, setHeight] = useState('Height');
  const [weight, setWeight] = useState('Weight');
  const [date, setDate] = useState('XXXX-XX-XX');
  const [eyeColor, setEyeColor] = useState('Eye Color');
  const [bodyChanges, setBodyChanges] = useState('Body Change');
  const [breastSizes, setBreastSizes] = useState('Breast Size');
  const [hairColor, setHairColor] = useState('Hair Color');
  const [languanges, setLanguanges] = useState('Language');
  const [pubicHair, setPubicHair] = useState('Pubic Hair');
  const [sexualPreferences, setSexualPreferences] = useState(
    'Sexual Preferences'
  );
  const [toys, setToys] = useState('Toys');
  const [ethinicity, setEthinicity] = useState('Ethinicity');
  const [aboutMe, setAboutMe] = useState('About Me');
  const [competence, setCompetence] = useState('Competence');
  const [whatAttractsMe, setWhatAttractsMe] = useState('What Attracts Me');
  // const [clientId, setClientId] = useState(localStorage.getItem('clientId'));
  const [loading, setLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const clientId = useSelector((state) => state?.user?.clientId);

  // useEffect(() => {
  //   setClientId(localStorage.getItem('clientId'));
  // }, []);

  const getInitialModelProfile = async () => {
    setModelLoading(true);

    try {
      const {
        data: {
          data: {
            firstName,
            gender,
            nickName,
            cityOfResident,
            nationality,
            country,
            countryId,
            build,
            dateOfBirth,
            lastName,
            aboutMe,
            whatAttractMe,
            myCompetence,
            toy,
            sexualPreference,
            pubicHair,
            language,
            hairColor,
            breastSize,
            bodyChanges,
            eyeColor,
            weight,
            height,
            ethinicity,
          },
        },
      } = await axios.get(`/ModelsProfile/${clientId}/GetModelById`);

      // check for null value in the callback function
      setFirstName(() => (firstName ? firstName : ''));
      setGender(() => (gender ? gender : ''));
      setNickName(() => (nickName ? nickName : ''));
      setCity(() => (cityOfResident ? cityOfResident : ''));
      setCountry(() => (country ? country?.countryName : ''));
      setCountryId(() => (countryId ? countryId : ''));
      setNationality(() => (nationality ? nationality : ''));
      setBuild(() => (build ? build : ''));
      setDate(() => (dateOfBirth ? dateOfBirth?.slice(0, 10) : 'XXXX-XX-XX'));
      setLastName(() => (lastName ? lastName : ''));
      setAboutMe(() => (aboutMe ? aboutMe : ''));
      setWhatAttractsMe(() => (whatAttractMe ? whatAttractMe : ''));
      setCompetence(() => (myCompetence ? myCompetence : ''));
      setToys(() => (toy ? toy : ''));
      setSexualPreferences(() => (sexualPreference ? sexualPreference : ''));
      setPubicHair(() => (pubicHair ? pubicHair : ''));
      setLanguanges(() => (language ? language : ''));
      setHairColor(() => (hairColor ? hairColor : ''));
      setBreastSizes(() => (breastSize ? breastSize : ''));
      setBodyChanges(() => (bodyChanges ? bodyChanges : ''));
      setEyeColor(() => (eyeColor ? eyeColor : ''));
      setWeight(() => (weight ? weight : ''));
      setHeight(() => (height ? height : ''));
      setEthinicity(() => (ethinicity ? ethinicity : ''));
      setModelLoading(false);
    } catch (error) {
      setModelLoading(false);
      notification({
        title: 'Model Loading Error',
        message: errorFormmatter(error),
        type: 'danger',
        position: 'top-center',
      });
    }
  };

  //
  const getInitialData = async () => {
    try {
      const {
        data: { models },
      } = await axios.get('/SetUp/GetAllCountries');
      setCountries(models);
    } catch (error) {
      notification({
        title: 'Data Fetching Error',
        message: errorFormmatter(error),
        type: 'danger',
        position: 'top-center',
      });
    }
  };

  //fetch model profile
  useEffect(() => {
    getInitialModelProfile();
  }, []);

  //fetch list of countries on load
  useEffect(() => {
    getInitialData();
  }, []);

  const addActiveClassToDropdowns = (e) => {
    e.currentTarget.classList.toggle('reg-data-select__active');
  };

  const validationSchema = yup.object().shape({
    FirstName: yup
      .string()
      .required('First Name is required')
      .max(255, 'First Name must be less than 250 characters'),
    LastName: yup
      .string()
      .required('Last Name is required')
      .max(255, 'First Name must be less than 250 characters'),
    Build: yup.string().required('Build is required'),
    Nationality: yup.string().required('Nationality is required'),
    CountryId: yup.string().required('Country is required'),
    CityOfResident: yup.string().required('City Of Residence is required'),
    NickName: yup.string().required('Nick Name is required'),
    Gender: yup.string().required('Gender is required'),
    Height: yup.string().required('Height is required'),
    Weight: yup.string().required('Weight is required'),
    HairColor: yup.string().required('Hair Color is required'),
    DateOfBirth: yup.string().required('Date of Birth is required'),
    EyeColor: yup.string().required('Eye Color is required'),
    BreastSize: yup.string().required('Breas Size is required'),
    PubicHair: yup.string().required('Public Hair is required'),
    Language: yup.string().required('Language is required'),
    SexualPreference: yup.string().required('Sexual Preference is required'),
    BodyChanges: yup.string().required('Body Changes is required'),
    Toy: yup.string().required('Toys is required'),
    Ethinicity: yup.string().required('Ethinicity is required'),
    AboutMe: yup
      .string()
      .required('About Me is required')
      .max(255, 'First Name must be less than 250 characters'),
    MyCompetence: yup
      .string()
      .required('My Competence is required')
      .max(255, 'First Name must be less than 250 characters'),
    WhatAttractMe: yup
      .string()
      .required('What Attracts me is required')
      .max(255, 'First Name must be less than 250 characters'),
  });

  const modelBio = {
    FirstName: firstName,
    LastName: lastName,
    Build: build,
    Nationality: nationality,
    CountryId: countryId,
    CityOfResident: city,
    NickName: nickName,
    Gender: gender,
    Height: height,
    Weight: weight,
    HairColor: hairColor,
    DateOfBirth: date,
    EyeColor: eyeColor,
    BreastSize: breastSizes,
    PubicHair: pubicHair,
    Language: languanges,
    SexualPreference: sexualPreferences,
    BodyChanges: bodyChanges,
    Toy: toys,
    Ethinicity: ethinicity,
    AboutMe: aboutMe,
    MyCompetence: competence,
    WhatAttractMe: whatAttractsMe,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const successful = await validateInput(validationSchema, modelBio);
    if (!successful) return;

    setLoading(true);
    const formData = new FormData();

    formData.append('FirstName', firstName);
    formData.append('LastName', lastName);
    formData.append('Build', build);
    formData.append('Nationality', nationality);
    formData.append('CountryId', Number(countryId));
    formData.append('CityOfResident', city);
    formData.append('NickName', nickName);
    formData.append('Gender', gender);
    formData.append('Height', height);
    formData.append('Weight', weight);
    formData.append('HairColor', hairColor);
    formData.append('DateOfBirth', date);
    formData.append('EyeColor', eyeColor);
    formData.append('BreastSize', breastSizes);
    formData.append('PubicHair', pubicHair);
    formData.append('Language', languanges);
    formData.append('SexualPreference', sexualPreferences);
    formData.append('BodyChanges', bodyChanges);
    formData.append('Toy', toys);
    formData.append('Ethinicity', ethinicity);
    formData.append('AboutMe', aboutMe);
    formData.append('MyCompetence', competence);
    formData.append('WhatAttractMe', whatAttractsMe);
    formData.append('RegisterId', clientId);

    try {
      const { data } = await axios.post(
        '/ModelsProfile/RegisterModel',
        formData
      );

      const {
        aboutMe,
        bodyChanges,
        breastSize,
        build,
        cityOfResident,
        country,
        countryId,
        dateOfBirth,
        ethinicity,
        eyeColor,
        firstName,
        gender,
        hairColor,
        height,
        language,
        lastName,
        myCompetence,
        nationality,
        nickName,
        pubicHair,
        sexualPreference,
        toy,
        weight,
        whatAttractMe,
      } = data?.data;

      setAboutMe(aboutMe);
      setFirstName(firstName);
      setDate(dateOfBirth?.slice(0, 10));
      setBodyChanges(bodyChanges);
      setBreastSizes(breastSize);
      setBuild(build);
      setCity(cityOfResident);
      setCountry(country?.countryName);
      setCountryId(countryId);
      setEthinicity(ethinicity);
      setEyeColor(eyeColor);
      setGender(gender);
      setHairColor(hairColor);
      setHeight(height);
      setLanguanges(language);
      setLastName(lastName);
      setCompetence(myCompetence);
      setNationality(nationality);
      setNickName(nickName);
      setPubicHair(pubicHair);
      setSexualPreferences(sexualPreference);
      setToys(toy);
      setWeight(weight);
      setWhatAttractsMe(whatAttractMe);

      notification({
        title: 'Profile Edit Success',
        message: data?.message,
        type: 'success',
        position: 'top-center',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Model Update Error',
        message: errorFormmatter(error),
        type: 'danger',
        position: 'top-center',
      });
    }
  };

  //  options for list of nationalities  dropdown
  const nationalityOptions = countries.length ? (
    countries.map((country) => (
      <div
        className='reg-data-select-list--item'
        onClick={(e) => setNationality(e.target.textContent)}
        key={nanoid()}
        style={{
          display: 'flex',
          paddingInline: '1rem',
          gap: '.1rem',
          alignItems: 'center',
        }}
      >
        <img
          src={`data:image/png;base64,${country.countryFlag}`}
          alt='country flag'
          style={{
            width: '25px',
            height: '25px',
            aspectRatio: '1/1',
            objectFit: 'contain',
          }}
        />
        {country?.countryName}
      </div>
    ))
  ) : (
    <p>No Data</p>
  );

  // map options for list of coutries dropdown
  const countryOptions = countries.length ? (
    countries.map((country) => (
      <div
        className='reg-data-select-list--item'
        onClick={() => {
          setCountryId(country?.countryId);
          setCountry(country?.countryName);
        }}
        key={nanoid()}
        style={{
          display: 'flex',
          paddingInline: '1rem',
          gap: '.1rem',
          alignItems: 'center',
        }}
      >
        <img
          src={`data:image/png;base64,${country.countryFlag}`}
          alt='country flag'
          style={{
            width: '25px',
            height: '25px',
            aspectRatio: '1/1',
            objectFit: 'contain',
          }}
        />
        {country?.countryName}
      </div>
    ))
  ) : (
    <p>No Data</p>
  );

  return (
    <main>
      {modelLoading ? (
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '40vh',
          }}
        >
          loading ...
        </section>
      ) : (
        <section className='reg-data'>
          <div className='container'>
            <div className='reg-data--subtitle'>
              Before you start fill in the form, please. It will help users find
              you easier!
            </div>
            <div className='page-title'>Model registration information</div>

            <form onSubmit={handleSubmit}>
              <div className='reg-data-row'>
                <div className='reg-data-left'>
                  <div className='reg-data-row--title'>
                    <span>Main information</span>
                  </div>
                  <div className='reg-data-row--subtitle'>
                    Information for the site administration it <br />
                    will not be shown to site visitors
                  </div>
                </div>
                <div className='reg-data-right'>
                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='First name'
                      type='text'
                      name='firstName'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='Last name'
                      type='text'
                      name='lastName'
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Build'
                        type='text'
                        readOnly='readOnly'
                        value={build}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 384,
                          }}
                        >
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setBuild(e.target.textContent)}
                          >
                            Slim
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setBuild(e.target.textContent)}
                          >
                            Slim Thick
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setBuild(e.target.textContent)}
                          >
                            Thick
                          </div>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Nationality'
                        type='text'
                        readOnly='readOnly'
                        value={nationality}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 384,
                          }}
                        >
                          {nationalityOptions}
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Country of Residence'
                        type='text'
                        readOnly='readOnly'
                        value={country}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 384,
                          }}
                        >
                          {countryOptions}
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='City of Residence'
                      type='text'
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className='reg-data-row'>
                <div className='reg-data-left'>
                  <div className='reg-data-row--title'>
                    <span>Personal data</span>
                  </div>
                  <div className='reg-data-row--subtitle'>
                    Completed information will be displayed <br />
                    in the profile and see for users
                  </div>
                </div>

                <div className='reg-data-right'>
                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='Nickname'
                      type='text'
                      name='nickname'
                      value={nickName}
                      onChange={(e) => setNickName(e.target.value)}
                    />
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Gender'
                        type='text'
                        readOnly='readOnly'
                        value={gender}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 144,
                          }}
                        >
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setGender(e.target.textContent)}
                          >
                            Male
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setGender(e.target.textContent)}
                          >
                            Female
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setGender(e.target.textContent)}
                          >
                            Undisclosed
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setGender(e.target.textContent)}
                          >
                            TransGender
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setGender(e.target.textContent)}
                          >
                            Other
                          </div>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Height'
                        type='text'
                        readOnly='readOnly'
                        value={height}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 384,
                          }}
                        >
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setHeight(e.target.textContent)}
                          >
                            Short
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setHeight(e.target.textContent)}
                          >
                            Average
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setHeight(e.target.textContent)}
                          >
                            Tall
                          </div>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='Weight'
                      type='text'
                      name='weight'
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </div>

                  <div className='reg-data-input reg-data-input_date'>
                    <div className='input-wrapper'>
                      <Date handleDateChange={setDate} date={date} />
                    </div>
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Eye color'
                        type='text'
                        readOnly='readOnly'
                        value={eyeColor}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 384,
                          }}
                        >
                          <div className='reg-data-select-list--item'>
                            Amber
                          </div>
                          <div className='reg-data-select-list--item'>Blue</div>
                          <div className='reg-data-select-list--item'>
                            Brown
                          </div>
                          <div className='reg-data-select-list--item'>Gray</div>
                          <div className='reg-data-select-list--item'>
                            Green
                          </div>
                          <div className='reg-data-select-list--item'>
                            Hazel
                          </div>
                          <div className='reg-data-select-list--item'>Red</div>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='Body changes'
                      type='text'
                      name='languages'
                      onChange={(e) => setBodyChanges(e.target.value)}
                      value={bodyChanges}
                    />
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Breast size'
                        type='text'
                        readOnly='readOnly'
                        value={breastSizes}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 384,
                          }}
                        >
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) =>
                              setBreastSizes(e.target.textContent)
                            }
                          >
                            Small
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) =>
                              setBreastSizes(e.target.textContent)
                            }
                          >
                            Medium
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) =>
                              setBreastSizes(e.target.textContent)
                            }
                          >
                            Large
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) =>
                              setBreastSizes(e.target.textContent)
                            }
                          >
                            Extra Large
                          </div>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Hair color'
                        type='text'
                        readOnly='readOnly'
                        value={hairColor}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 384,
                          }}
                        >
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setHairColor(e.target.textContent)}
                          >
                            Blonde
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setHairColor(e.target.textContent)}
                          >
                            Brunette
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setHairColor(e.target.textContent)}
                          >
                            Red
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setHairColor(e.target.textContent)}
                          >
                            Black
                          </div>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='Languages'
                      type='text'
                      name='languages'
                      onChange={(e) => setLanguanges(e.target.value)}
                      value={languanges}
                    />
                  </div>

                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='Pubic hair'
                      type='text'
                      name='pubicHair'
                      onChange={(e) => setPubicHair(e.target.value)}
                      value={pubicHair}
                    />
                  </div>

                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='Sexual Preferences'
                      type='text'
                      name='sexualPreferences'
                      onChange={(e) => setSexualPreferences(e.target.value)}
                      value={sexualPreferences}
                    />
                  </div>

                  <div className='reg-data-input'>
                    <div
                      className='reg-data-select'
                      onClick={addActiveClassToDropdowns}
                    >
                      <input
                        placeholder='Ethinicity'
                        type='text'
                        readOnly='readOnly'
                        value={ethinicity}
                      />
                      <div className='reg-data-select-list'>
                        <Scrollbar
                          style={{
                            height: 384,
                          }}
                        >
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setEthinicity(e.target.textContent)}
                          >
                            Black
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setEthinicity(e.target.textContent)}
                          >
                            White
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setEthinicity(e.target.textContent)}
                          >
                            Asia
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setEthinicity(e.target.textContent)}
                          >
                            Indian
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setEthinicity(e.target.textContent)}
                          >
                            Latino
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setEthinicity(e.target.textContent)}
                          >
                            Arab
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setEthinicity(e.target.textContent)}
                          >
                            Native America
                          </div>
                          <div
                            className='reg-data-select-list--item'
                            onClick={(e) => setEthinicity(e.target.textContent)}
                          >
                            Italian
                          </div>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>

                  <div className='reg-data-input placeCustom'>
                    <input
                      required
                      placeholder='Toys'
                      type='text'
                      name='Toys'
                      onChange={(e) => setToys(e.target.value)}
                      value={toys}
                    />
                  </div>
                </div>
              </div>

              <div className='reg-data-row reg-data-row__textarea'>
                <div className='reg-data-left'>
                  <div className='reg-data-row--title'>
                    <span>About me </span>
                  </div>
                  <div className='reg-data-row--subtitle'>
                    No more than 1000 characters
                  </div>
                </div>
                <div className='reg-data-right'>
                  <div className='reg-data-input'>
                    <textarea
                      placeholder='Tell us about you'
                      maxLength='1000'
                      onChange={(e) => setAboutMe(e.target.value)}
                      value={aboutMe}
                    />
                  </div>
                </div>
              </div>

              <div className='reg-data-row reg-data-row__textarea'>
                <div className='reg-data-left'>
                  <div className='reg-data-row--title'>
                    <span>My competence</span>
                  </div>
                  <div className='reg-data-row--subtitle'>
                    No more than 1000 characters
                  </div>
                </div>
                <div className='reg-data-right'>
                  <div className='reg-data-input'>
                    <textarea
                      placeholder='Let`s talk about your competece'
                      maxLength='1000'
                      onChange={(e) => setCompetence(e.target.value)}
                      value={competence}
                    />
                  </div>
                </div>
              </div>
              <div className='reg-data-row reg-data-row__textarea'>
                <div className='reg-data-left'>
                  <div className='reg-data-row--title'>
                    <span>What attracts me</span>
                  </div>
                  <div className='reg-data-row--subtitle'>
                    No more than 1000 characters
                  </div>
                </div>
                <div className='reg-data-right'>
                  <div className='reg-data-input'>
                    <textarea
                      placeholder='What attracts you'
                      maxLength='1000'
                      onChange={(e) => setWhatAttractsMe(e.target.value)}
                      value={whatAttractsMe}
                    />
                  </div>
                </div>
              </div>
              <button
                className='btn btn__red'
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </form>
          </div>
        </section>
      )}
    </main>
  );
};
export default RegistrationModelInfo;







/* <<<<<<< HEAD
import React, { Component } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import Date from "../components/date/Date";

class RegistrationModelInfo extends Component {
    componentDidMount () {
        // Кастомный селект
        document.querySelectorAll('.reg-data-select').forEach(function(element) {
            element.onclick = function() {
                this.classList.toggle('reg-data-select__active');
            };
        });
        // Выбор в кастомном селекте
        document.querySelectorAll('.reg-data-select-list--item').forEach(function(element) {
            element.onclick = function() {
                let textContent = this.textContent;
                this.closest('.reg-data-select').querySelector('input').value = textContent;
            };
        });
    }
    render() {
        return (
            <main>
                <section className="reg-data">
                    <div className="container">
                        <div className="reg-data--subtitle">
                            Before you start fill in the form, please. It will help users find you easier!
                        </div>
                        <div className="page-title">
                            Model registration information
                        </div>
                        <form>
                            <div className="reg-data-row">
                                <div className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>Main information</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        Information for the site administration it <br/>
                                        will not be shown to site visitors
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <div className="reg-data-input placeCustom">
                                        <input required placeholder="First name" type="text"/>
                                        <span>First name</span>
                                    </div>
                                    <div className="reg-data-input placeCustom">
                                        <input required placeholder="Last name" type="text"/>
                                        <span>Last name</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Build" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input placeCustom">
                                        <input required placeholder="Nationality" type="text"/>
                                        <span>Nationality</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Country" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="City of residence" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="reg-data-row">
                                <div  className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>Personal data</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        Completed information will be displayed <br/>
                                        in the profile and see for users
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <div className="reg-data-input placeCustom">
                                        <input required placeholder="Nickname" type="text"/>
                                        <span>Nickname</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Gender" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 144 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Male
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Female
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        indefinite
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Height" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Weight" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Build" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input reg-data-input_date">
                                        <div className="input-wrapper">
                                            <Date/>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Eye color" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Body changes" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Breast size" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Hair color" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Languages" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Pubic hair" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Sexual preferences" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Toys" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="reg-data-row reg-data-row__textarea">
                                <div  className="reg-data-left">
                                    <div className="reg-data-row--title">
                                    <span>About me </span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        No more than 1000 characters
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <div className="reg-data-input">
                                        <textarea 
                                            placeholder="Tell us about you"
                                            maxLength="1000">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="reg-data-row reg-data-row__textarea">
                                <div  className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>My competence</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        No more than 1000 characters
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <div className="reg-data-input">
                                        <textarea 
                                            placeholder="Let`s talk about your competece"
                                            maxLength="1000">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="reg-data-row reg-data-row__textarea">
                                <div  className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>What attracts me</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        No more than 1000 characters
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <div className="reg-data-input">
                                        <textarea 
                                            placeholder="What attracts you"
                                            maxLength="1000">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn__red">
                                Save
                            </button>
                        </form>
                    </div>
                </section>
            </main>
        );
    }
}
export default RegistrationModelInfo;
======= */
//>>>>>>> 59d03e77e78a8be035b39ba65f4989f1c2ae5150
