import React, { Component } from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';
import { useActionCreator } from '../../hooks/useActionCreators';

const Confirm = () => {
   const { openModal } = useActionCreator();
   // componentDidMount() {
   //     let getUrlParameter = function getUrlParameter(sParam) {
   //         let sPageURL = window.location.search.substring(1);
   //         let sURLVariables = sPageURL.split('&amp;');
   //         let sParameterName;
   //         let i;

   //         for (i = 0; i < sURLVariables.length; i++) {
   //             sParameterName = sURLVariables[i].split('=');

   //             if (sParameterName[0] === sParam) {
   //                 return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
   //             }
   //         }
   //     };
   //     let getEmail = getUrlParameter('email');
   //     let getToken = getUrlParameter('token');
   //     axios({
   //         method: 'post',
   //         url: `${axios.defaults.baseURL}/api/users/confirm-email`,
   //         data: {
   //             email: getEmail,
   //             emailConfirmationToken: getToken
   //         }
   //     }).then(function (response) {

   //     });
   // }

   return (
      <main className='thanks-page'>
         <div className='container'>
            <div className='reg-model-thanks'>
               <div className='reg-model-thanks--title'>
                  Thank you <br />
                  for account verification!
               </div>
               <div className='reg-model-thanks--subtitle'>
                  We excited to have you in our platform <br />
                  you can login to update your profile
               </div>
               <Link to='/' className='reg-model-thanks--link btn btn__red'>
                  Return to main page
               </Link>
            </div>
         </div>
      </main>
   );
};
export default Confirm;